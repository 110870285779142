import { default as React, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import './App.css';

import axios from 'axios';
import { values, every, keys, pickBy } from 'lodash';
import qs from 'qs'
import useSwr from 'swr';

import { makeStyles, styled, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import BasePaper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';

import BaseAlert, { AlertProps } from '@material-ui/lab/Alert';

/**
 *
 */
const Alert = (props: AlertProps) => {
  return <BaseAlert elevation={6} variant="filled" {...props} />;
}

/**
 *
 */
interface FormData {
  url: string,
  startTime: string,
  endTime: string
}

/**
 *
 */
interface Message {
  severity: AlertProps['severity'],
  content: string
}

const Paper = styled(BasePaper)({
  padding: '2rem',
  width: '100%'
});

/**
 *
 */
const useStyles = makeStyles({
  root: {},
  input: {
    '&:not(:last-child)': {
      marginBottom: '1rem'
    }
  },
  accordion: {
    marginBottom: '1rem'
  },
  accordionDetails: {
    display: 'block',
    '& > *:not(:last-child)': {
      marginBottom: '1rem'
    }
  },
  container: {
  },
  card: {
    marginBottom: '1rem'
  },
  thumbnail: {
    height: '150px'
  }
});

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

const validations: Record<keyof FormData, RegExp> = {
  url: /^https?:\/\//,
  startTime: /^((\d{1,2}:)?[0-5]?[0-9]:)?[0-5]?[0-9]$/,
  endTime: /^((\d{1,2}:)?[0-5]?[0-9]:)?[0-5]?[0-9]$/
};

const client = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT
});

/**
 *
 */
const fetcher = (url: string) => client.get(url).then(res => res.data)

function App() {
  const styles = useStyles();

  const [ formData, setFormData ] = useState({
    url: '',
    startTime: '00:00:00',
    endTime: ''
  } as FormData);

  const [ messages, setMessages ] = useState<Message[]>([]);
  const [ downloading, setDownloading ] = useState(false);

  const urlInputRef = useRef<HTMLInputElement>(null);

  /**
   *
   */
  const updateForm = useCallback(
    <P extends keyof FormData>(
      property: P,
      value: FormData[P]
    ) => setFormData({...formData, [property]: value}),
    [formData]
  );

  /**
   *
   */
  const validateFormData = (formData: FormData) => {
    const results = {} as Record<keyof FormData, boolean>;

    for (const key of keys(formData) as Array<keyof FormData>) {
      results[key] = true;

      if (!formData[key] || formData[key].trim().match(validations[key])) continue;

      results[key] = false;
    }

    return results;
  };

  /**
   *
   */
  const openVideo = useCallback(() => {
    window.open(formData.url, '_blank');
  }, [formData]);

  /**
   *
   */
  const removeMessage = (index: number) =>
    setMessages([
      ...messages.slice(0, index),
      ...messages.slice(index + 1)
    ]);

  /**
   *
   */
  const showMessage = useCallback(
    (message: Message) => setMessages([...messages, message]),
    [setMessages, messages]
  );

  /**
   *
   */
  const downloadVideo = useCallback(async () => {
    const params = qs.stringify(pickBy(formData));
    setDownloading(true);

    try {
      await client.get<null>(`/validate?${params}`);
    } catch (e) {
      showMessage({severity: 'error', content: e.response.data});
      return;
    } finally {
      setDownloading(false);
    }

    showMessage({severity: 'info', content: 'The video will download shortly!'});

    window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/download?${params}`;

  }, [formData, showMessage]);

  /**
   *
   */
  const validationResults = useMemo(
    () => validateFormData(formData),
    [formData]
  );

  const { data, isValidating: isFetchingVideo, mutate: refresh, error } = useSwr(
    formData.url && validationResults.url ?
      `/details?url=${formData.url}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      initialData: null
    }
  );

  /**
   *
   */
  const isDataValid = useCallback(
    () => every(values(validationResults), passed => passed),
    [validationResults]
  );

  /**
   *
   */
  const hasVideo = useCallback(
    (): boolean => data && !isFetchingVideo && !error,
    [data, isFetchingVideo, error]
  );

  /**
   *
   */
  const canDownload = useCallback(
    () => hasVideo() && isDataValid(),
    [hasVideo, isDataValid]
  );

  /**
   *
   */
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && canDownload()) downloadVideo();
    };

    document.addEventListener('keypress', listener);

    return () => document.removeEventListener('keypress', listener);
  }, [canDownload, downloadVideo]);

  return <MuiThemeProvider theme={theme}>
    <Container className={styles.root} maxWidth="sm">
      <Box
        display='flex'
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        minWidth='100%'
      >
        <Paper className={styles.container}>
          <TextField
            inputRef={urlInputRef}
            error={!validationResults.url}
            className={styles.input}
            label="Video URL"
            fullWidth
            variant="outlined"
            onBlur={e => updateForm('url', e.target.value)}
            onChange={e => updateForm('url', e.target.value)}
            disabled={isFetchingVideo || downloading}
            defaultValue={formData.url}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {isFetchingVideo ?
                  <CircularProgress color="secondary" />
                : hasVideo() ?
                  <IconButton onClick={refresh}>
                    <RefreshIcon />
                  </IconButton>
                :
                  undefined
                }
              </InputAdornment>
            }}
           ></TextField>

          {hasVideo() ?
            <Box>
              <Card className={styles.card} variant="outlined">
                <CardActionArea onClick={e => openVideo()}>
                  <CardMedia
                    className={styles.thumbnail}
                    image={data.thumbnail}
                    title={data.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {data.title}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      {data.duration}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Test
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Accordion className={styles.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography>Options</Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                  <TextField
                    error={!validationResults.startTime}
                    label="Start time (optional)"
                    placeholder="Eg. 00:00:05"
                    fullWidth
                    defaultValue={formData.startTime}
                    disabled={downloading}
                    onChange={e => updateForm('startTime', e.target.value)}
                   ></TextField>
                  <TextField
                    error={!validationResults.endTime}
                    label="End time (optional)"
                    placeholder="Eg. 00:00:10"
                    fullWidth
                    defaultValue={formData.endTime}
                    disabled={downloading}
                    onChange={e => updateForm('endTime', e.target.value)}
                   ></TextField>
                </AccordionDetails>
              </Accordion>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                endIcon={downloading ? <CircularProgress color="secondary" size="1rem" /> : <GetAppIcon />}
                disabled={!canDownload() || downloading}
                onClick={e => downloadVideo()}
              >
                Download
              </Button>
            </Box>
            :
            null
          }
        </Paper>
      </Box>

      {messages.map((message, i) =>
        <Snackbar key={i} open autoHideDuration={6000} onClose={e => removeMessage(i)}>
          <Alert severity={message.severity}>{message.content}</Alert>
        </Snackbar>
      )}
    </Container>
   </MuiThemeProvider>
}

export default App;
